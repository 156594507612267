li {
    list-style-type: none;
}

.nav-link {
    color: white;
    width: fit-content;
    padding: 5px;
    border-radius: 10px;
}

.nav-link:hover {
    background-color: white;
}

.nav-item {
    display: flex;
    justify-content: center;
}
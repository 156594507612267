/* * {
    outline: 1px solid red;
    outline-offset: 1px solid red;
} */

body, html {
    height: 100%;
}

.hero-background {
    background-color: #1b283b;
    height: 50rem;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.about-image {
    margin-top: -30px
}

p {
    font-size: 1.4rem;
}

li {
    font-size: 1.1rem;
}

i {
    padding: 5px
}

i:hover {
    background-color: gray;
    border-radius: 5px;
}

.map-container {
    width: 100%;
    height: 50vh
}

.hero-text {
    position: absolute;
    top: 30%;
    width: 100%;
}

.btn-light {
    margin-bottom: 10px;
}

.project-button {
    transition: ease-in 0.2s;
}

.project-button:hover {
    opacity: 90%;
}

@media (min-width: 1000px) {
    .hero-text {
        position: absolute;
        top: 35%;
        left: 10%;
        width: 60%
          }
    .about-header h1 {
        margin: 20px auto 30px auto,
    }
}

    